import React from 'react';
import './Loading.css'; 

function Loading() {
  return ( 
      <div className='loadding-ppl'>
        <img src="https://chpriv.com/box-cdn/provenco/icon.png" alt="" /> 
      </div> 
  );
}

export default Loading;