import React, { useState, useEffect } from 'react';
import Axios from 'axios'; 
import { Link } from 'react-router-dom';
import { Col, Form, Button } from 'react-bootstrap';
import './FooterPage.css';
import QRCodeGenerator from '../../../partscomp/Qr';

const FooterPage = () => { 
  const [showModal, setShowModal] = useState(false); 
  const [iPais, setCountry] = useState(null);
  const [iCiudad, setCity] = useState(null);

  useEffect(() => { 
    Axios.get(`https://twilio.chpriv.com/ingsux/ipquery/reverse`)
      .then((response) => {
        if (response.data.reqip === false) {
          // Manejar el caso de error aquí si es necesario
        } else { 
          const Pais = response.data.vContry;
          const Ciudad = response.data.vCity;       
          setCountry(Pais);        
          setCity(Ciudad);           
        }
      })
      .catch((error) => { 
        console.error('Ups, ErVDip');
      }); 
  }, []);

  const openModal = () => {
    setShowModal(true);
  };  

  return (
    <div className="iFooter"> 
      <div className="container"> 
        <div className="iFooter-column">
          <div className="iFooter-pie">
            <div className='iFooter-pie-l'>
              <span className="text-14-s tbg-withe">&nbsp;Provenco&nbsp;&copy;&nbsp;2019-2024 &nbsp; <span>DYP Logistica</span></span>
            </div>
            <div className='iFooter-pie-r'>

            <div className="Box-cajas-x">
              <Link className="link-back-w" to="" onClick={() => { openModal(); }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-qr-code-scan" viewBox="0 0 16 16">
                    <path d="M0 .5A.5.5 0 0 1 .5 0h3a.5.5 0 0 1 0 1H1v2.5a.5.5 0 0 1-1 0zm12 0a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0V1h-2.5a.5.5 0 0 1-.5-.5M.5 12a.5.5 0 0 1 .5.5V15h2.5a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1 0-1H15v-2.5a.5.5 0 0 1 .5-.5M4 4h1v1H4z"/>
                    <path d="M7 2H2v5h5zM3 3h3v3H3zm2 8H4v1h1z"/>
                    <path d="M7 9H2v5h5zm-4 1h3v3H3zm8-6h1v1h-1z"/>
                    <path d="M9 2h5v5H9zm1 1v3h3V3zM8 8v2h1v1H8v1h2v-2h1v2h1v-1h2v-1h-3V8zm2 2H9V9h1zm4 2h-1v1h-2v1h3zm-4 2v-1H8v1z"/>
                    <path d="M12 9h2V8h-2z"/>
                  </svg>
              </Link>
            </div>
            <span className="text-14-s tbg-withe"> {iPais ? <span>{iPais}-{iCiudad}</span> : <span>Ubicando...</span>}</span>
            </div> 
          </div>
        </div>
      </div>

      {showModal && (
          <div className="modal vi-modal-dark fade show" tabIndex="-1" style={{ display: 'block' }} onClick={() => setShowModal(false)}>
            <div className="iVContent-Video">
              <div className="modal-dialog modal-lg">
                <div className="iV-MVideio">  {/* onClick={() => { openModal(); }} */}
                  <QRCodeGenerator />
                </div>
              </div>
            </div>
          </div>
        )}
    </div >
  );
};

export default FooterPage;
