import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import './Qr.css'; 

function Qr() {
    const [url, setUrl] = useState('https://regist.provenco.co/regist'); // Aquí debes definir tu dirección web

    return (
        <div className="BozQr"> 
            {url && (
                <div>
                    <p>REGISTRO</p>
                    <QRCode value={url} />
                </div>
            )}
        </div>
    );
}

export default Qr;
